// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("/opt/build/repo/src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-our-process-js": () => import("/opt/build/repo/src/pages/our-process.js" /* webpackChunkName: "component---src-pages-our-process-js" */),
  "component---src-pages-the-collaborative-js": () => import("/opt/build/repo/src/pages/the-collaborative.js" /* webpackChunkName: "component---src-pages-the-collaborative-js" */),
  "component---src-pages-the-tool-js": () => import("/opt/build/repo/src/pages/the-tool.js" /* webpackChunkName: "component---src-pages-the-tool-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

